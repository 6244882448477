import React, { useState } from "react";
import Project from "./project";
import INFO from "../../data/user";
import "./styles/allProjects.css";

const AllProjects = () => {
	const [projectType, setProjectType] = useState("all");

	const handleProjectType = (e) => {
		setProjectType(e.target.dataset.type);
	};

	return (
		<>
			<div className="select-project-type">
				<p>Show projects that are:</p>
				<ul>
					<li
						data-type="all"
						onClick={handleProjectType}
						className={`${projectType === "all" ? "active" : ""}`}
					>
						All Types
					</li>

					<li
						data-type="dev"
						onClick={handleProjectType}
						className={`${projectType === "dev" ? "active" : ""}`}
					>
						Dev
					</li>
					<li
						data-type="animation"
						onClick={handleProjectType}
						className={`${
							projectType === "animation" ? "active" : ""
						}`}
					>
						Animation
					</li>
					<li
						data-type="both"
						onClick={handleProjectType}
						className={`${projectType === "both" ? "active" : ""}`}
					>
						Design + Dev
					</li>
					<li
						data-type="print"
						onClick={handleProjectType}
						className={`${projectType === "print" ? "active" : ""}`}
					>
						Print
					</li>
					<li
						data-type="working"
						onClick={handleProjectType}
						className={`${
							projectType === "working" ? "active" : ""
						}`}
					>
						Demos
					</li>
				</ul>
				{console.log("newType", projectType)}
			</div>

			<div className="all-projects">
				{INFO.projects.map(
					(project, index) =>
						project.show !== false &&
						(projectType === "all" ||
							projectType === project.type) && (
							<div className="project-content" key={index}>
								<Project
									softwareLogos={project.logos}
									title={project.title}
									description={project.description}
									thumb={project.thumb}
									type={project.type}
									link={project.link}
									linkText={project.linkText}
								/>
							</div>
						)
				)}
			</div>
		</>
	);
};

export default AllProjects;
